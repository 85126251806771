.nav {
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  /* position: sticky;
  top: 5rem;
  z-index: 999; */
  background-color: #c7f9cc;
}

.navLink {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 4rem;
  height: 1.1rem;
  border-radius: 0.25rem;
}

.navLink:visited {
  color: #22577a;
}
.navLink:hover {
  background-color: #22577a;
  color: #c7f9cc;
  font-size: 1.1rem;
  transition: 0.25s;
}

@media (min-width: 600px) {
  .App {
    display: grid;
    grid-template-areas:
      ". header header ."
      ". nav main ."
      ". footer footer .";
    grid-template-columns: 5% 15% auto 5%;
    grid-template-rows: auto auto;
  }

  .nav {
    grid-area: nav;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: sticky;
    top: 5rem;
    z-index: 999;
    height: 11rem;
  }
}
