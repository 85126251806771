.leaderCard {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #e1eff6;
  padding: 0.25rem;
  margin: 0.25rem;
  border-radius: 0.25rem;
  width: 95%;
}

.leaderCardPos {
  width: 3rem;
}

.leaderCardPlyr {
  width: 5rem;
}

.leaderCardLBPts {
  width: 3rem;
}

.leaderCardHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
