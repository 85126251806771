@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  /* width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%; */
  animation: spinner 1.5s linear infinite;
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 350px;
  padding: 2rem;
}

.mantis {
  width: 100px;
  height: 100px;
}
