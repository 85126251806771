.section {
  padding: 1rem;
}

.skillsCard {
  padding: 0.5rem;
  margin: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0.5rem 0.75rem 1rem #57cc99;
}

@media (min-width: 600px) {
  .App {
    display: grid;
    grid-template-areas:
      '. header header .'
      '. nav main .'
      '. footer footer .';
    grid-template-columns: 5% 15% auto 5%;
    grid-template-rows: auto auto;
  }

  .section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  h2 {
    width: 100%;
  }

  .skillsCard {
    width: 15rem;
  }
}
