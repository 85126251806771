.form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #e1eff6;
}

.inputBox {
  margin: 0.5rem;
  padding: 0.5rem;
  text-align: center;
}
