.portrait {
  max-width: 50%;
  border-radius: 1rem;
  padding: 0.5rem;
}

.section {
  padding: 1rem;
}

.famSki {
  padding: 0.5rem;
  max-width: 80%;
}

@media (min-width: 600px) {
  .App {
    display: grid;
    grid-template-areas:
      '. header header .'
      '. nav main .'
      '. footer footer .';
    grid-template-columns: 5% 15% auto 5%;
    grid-template-rows: auto auto;
  }

  .portrait {
    max-width: 150px;
  }

  .famSki {
    max-width: 50%;
  }
}
