.playersContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.article {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  border-radius: 0.25rem;
  background-color: #e1eff6;
  border: 1px solid #22577a;
  width: 5rem;
  height: 2rem;
}

.fieldset {
  margin: 1rem;
  padding: 1rem;
}
