.form {
  border: 2px #e1eff6 solid;
  padding: 0.5rem;
  margin: 0.5rem;
}

.scoreCard {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #e1eff6;
  padding: 0.25rem;
  margin: 0.25rem;
  border-radius: 0.25rem;
}

.selecterBox {
  padding: 0.1rem;
  margin: 0.25rem;
  height: 1.75rem;
}

.stablefordInput {
  width: 5rem;
  height: 1.5rem;
  text-align: center;
}

.roundRef {
  width: 10rem;
  height: 1.5rem;
  text-align: center;
}

.positionLabel {
  width: 3rem;
}

.roundInfo {
  margin: 0.5rem;
}

.errorMsg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 0.75rem;
  color: red;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
}
