* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  background-color: #c7f9cc;
}

#lost-page {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  border: solid #ff7d00 6px;
  width: 80%;
  background-color: #001524;
  color: #ffecd1;
  text-align: center;
  margin: 2rem auto;
  padding: 2rem;
}

#lost-page img {
  width: 100%;
  margin: 1rem auto;
}

.strak-app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  background-color: white;
  border: 1px solid;
  margin: 1rem;
}

.strak-subheader {
  margin: 0.5rem;
}
.portal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.strak-button {
  margin: 0.25rem 1rem;
  background-color: #c7f9cc;
  padding: 0.25rem;
}
.strak-button__delete {
  background-color: rgba(255, 58, 58, 0.72);
}

.strak-button:hover {
  font-weight: bold;
  background-color: #f2fff4;
}

.errorMsg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 0.75rem;
  color: red;
}

@media (min-width: 600px) {
  .App {
    display: grid;
    grid-template-areas:
      ". header header ."
      ". nav main ."
      ". footer footer .";
    grid-template-columns: 5% 15% auto 5%;
    grid-template-rows: auto auto;
  }

  .portal {
    grid-area: main;
  }
}
