.logo {
  max-height: 5rem;
}

.section {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  background-color: #57cc99;
  color: #143246;
  position: sticky;
  top: 0;
  z-index: 999;
}

.h1 {
  font-size: 1.75rem;
}

@media (min-width: 600px) {
  .App {
    display: grid;
    grid-template-areas:
      '. header header .'
      '. nav main .'
      '. footer footer .';
    grid-template-columns: 5% 15% auto 5%;
    grid-template-rows: auto auto;
  }

  .section {
    grid-area: header;
    display: flex;
    justify-content: space-around;
  }
}
